import React from 'react'
import {AnnouncementProps} from '../../types/AnnouncementProps'
import {Box, Grid, Typography} from '@material-ui/core'
import {useStyles} from './AnnouncementStyle'
import CrossIcon from '@atlaskit/icon/glyph/cross'


const Announcement = (props: AnnouncementProps) => {

    const classes = useStyles()

    return (
        <>
            {!props.isDismissed &&
                <Grid container className={[classes.container, classes[props.appearance] as any].join(' ')}>
                    {props.closeButtonHandler && <Box className={classes.closeIcon}
                             onClick={props.closeButtonHandler}>
                            <CrossIcon label="close"/>
                        </Box>
                    }
                    <Grid container className={classes.containerContent}>
                        <Grid item xs={4} className={classes.containerImage}>
                            <img alt={props.title} src={props.imagePath} className={classes.image}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item><Typography
                                className={classes.title}>{props.title}</Typography></Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subtitle}>
                                    {props.subtitle}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {props.children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}
export default Announcement
