import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addReaction: {
            marginRight: 8
        },
        avatarContainer: {
            marginRight: 11,
            marginTop: 30
        },
        addReplyReaction: {
            marginRight: 18
        },
        pickerAboveContainer: {
            position: 'relative',
        },
        commentContentContainer: {
            padding: 5,
            backgroundColor: '#FFFFFF',
            hyphens: 'auto',
            wordBreak: 'break-word',
            WebkitHyphenateCharacter: "'\\2011'"
        },
        floatPickerContainer: {
            position: 'absolute',
            left: 26,
            zIndex: 999
        },
        floatPickerContainerThread: {
            top: '-303px',
            right: '158px',
            left: 'unset',
            '@media(min-height: 1499px)': {
                right: '115px'
            }
        },
        floatPickerContainerThreadWithScroll: {
            right: '120px'
        },
        avatar: {
            height: 30,
            width: 30,
            fontSize: '0.8em'
        },
        defaultAvatar: {
            '& > span': {
                height: 30,
                width: 30,
                '& > svg': {
                    height: 30,
                    width: 30
                }
            }
        },
        commentAuthorName: {
            fontFamily: 'SF Pro Text Bold',
            fontSize: 14,
            lineHeight: '16px',
            color: '#172B4E'
        },
        commentDate: {
            fontFamily: 'SF Pro Text Semibold',
            fontSize: 12,
            lineHeight: '16px',
            color: '#6B778C'
        },
        commentContainer: {
            padding: '8px 6px 12px 12px',
            backgroundColor: '#e0e2e8',
            borderRadius: '3px'
        },
        commentReplyContainer: {
            paddingTop: 8
        }
    }),
)

export { useStyles }
