import React from "react"
import SectionMessage, {SectionMessageAction} from '@atlaskit/section-message'
import {ReadonlyWarningProperties} from '../../types/ReadonlyWarningProperties'
import {Grid, Link} from '@material-ui/core'
import {useStyles} from './ReadonlyWarningStyles'
import {supportUrl} from 'trello-shared-resources/dist'


export const ReadonlyWarning = (props: ReadonlyWarningProperties) => {

    const classes = useStyles()

    const {licenseDetails} = props
    const CustomLink = React.forwardRef(
        /* istanbul ignore next */
        (props = {}, ref: React.Ref<HTMLButtonElement>) => (
            <Link ref={ref} {...props} target="_blank"/>
        )
    )

    let actions = [<SectionMessageAction href={supportUrl} linkComponent={CustomLink}>Contact Support</SectionMessageAction>]
    if(props.upgradeHandler) actions = [
        <SectionMessageAction onClick={props.upgradeHandler}>Get a license</SectionMessageAction>,
        ...actions
    ]

    return <>
        {!licenseDetails.isLicensed() && <Grid container spacing={0} className={[classes.grid, classes.readonlyWarning].join(' ')}>
            <SectionMessage
                appearance="warning"
                title="Oops! You do not have an active license."
                actions={actions}
            >
                {props.upgradeHandler ?
                    "Threaded Comments is now read-only meaning you can read all the previous comments, but you can not add any new comments. To gain full access to Threaded Comments, please ask the person who installed this Power-Up on this board to create/purchase a license, by clicking the link below. Or contact our Support team for help." :
                    "Threaded Comments is now read-only meaning you can read all the previous comments, but you can not add any new comments. To gain full access to Threaded Comments, log in to your account to upgrade your license."}
            </SectionMessage>
        </Grid>}
    </>
}