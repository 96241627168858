/**
 * Check if a user will see the pricing banner. They need to meet these requirements:
 *     - it's one week to expire their evaluation license
 * @param licenseDetails
 * @return true if the user will see the pricing banner
 */
export const showPricingBanner = (licenseDetails: any) => {
    if (!licenseDetails || (!licenseDetails.isOnlyEvalLicensed() && licenseDetails.isLicensed()) || !licenseDetails.isLicensed()) return false
    const evaluationEndDate = licenseDetails.licenseCapabilitiesEndDates['Evaluation']
    const millis7days = 7 * 24 * 60 * 60 * 1000
    return (evaluationEndDate - new Date().getTime()) < millis7days
}

/**
 * Calculate the number of days for an evaluation license to expire
 * @param licenseDetails
 * @return the number of days to expire or 0 otherwise
 */
export const calculateDaysToExpireEvaluation = (licenseDetails: any) => {
    if (!licenseDetails || !licenseDetails.licenseCapabilitiesEndDates || (!licenseDetails.isOnlyEvalLicensed() && licenseDetails.isLicensed()) || !licenseDetails.isLicensed()) return 0
    const evaluationEndDate = licenseDetails.licenseCapabilitiesEndDates['Evaluation']
    const dayInMillis: number = 24 * 60 * 60 * 1000
    const daysToExpireEvaluation = (evaluationEndDate - new Date().getTime()) / dayInMillis
    return evaluationEndDate && daysToExpireEvaluation > 0 ? Math.ceil(daysToExpireEvaluation) : 0
}
