import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            grid: {
                marginTop: 11,
                marginBottom: 12
            },
            readonlyWarning: {
                '& ul': {
                    marginBottom: 0,
                }
            }
        }
    )
)

export { useStyles }
