import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { getUserEmailForNotifications } from '../../../modules/Persistence'
import EmailNotificationsStep from './emailNotificationsStep/EmailNotificationsStep'
import OnboardingProperties from 'trello-shared-resources/dist/types/OnboardingProperties'
import MemberEmail from '../../../types/MemberEmail'
import { ThreadedCommentsEmailCheckoutSteps } from '../../../types/ThreadedCommentsEmailCheckoutSteps'
import { throwError } from '../../../modules/UIUtils'
import { useErrorHandler } from 'react-error-boundary'

/**
 * Main component used to load data from db and show the proper page accordingly
 */
const EmailNotificationsCheckout = (props: OnboardingProperties) => {
    const [userEmail, setUserEmail] = useState<null | MemberEmail>(null)
    const [currentStep, setCurrentStep] = useState<ThreadedCommentsEmailCheckoutSteps>(ThreadedCommentsEmailCheckoutSteps.COLLECT)
    const handleError = useErrorHandler()

    const licenseDetails = props.licenseDetails
    const trelloIframeContext = licenseDetails.trelloIframeContext

    useEffect(() => {
        if (trelloIframeContext && trelloIframeContext.getContext) {
            getUserEmailForNotifications(trelloIframeContext).then((result: any) => {
                setUserEmail(result)
                if (result && result.email) setCurrentStep(ThreadedCommentsEmailCheckoutSteps.SUCCESS)
            }).catch((error) => throwError(() => handleError(error), trelloIframeContext))
        }
    }, [trelloIframeContext, handleError])

    if (licenseDetails.isLoading || userEmail === null) {
        return <Skeleton active={true}/>
    }

    return <EmailNotificationsStep licenseDetails={licenseDetails} setCurrentStep={setCurrentStep}
                                   currentStep={currentStep}/>
}

export default EmailNotificationsCheckout