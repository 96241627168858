import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '24px 19px',
            marginBottom: 15
        },
        information: {
            backgroundColor: '#DEEBFF',
        },
        warning: {
            backgroundColor: '#FFFAE6',
        },
        error: {},
        success: {},
        discovery: {},
        containerImage: {},
        containerContent: {},
        title: {
            fontFamily: 'SF UI Text SemiBold',
            color: '#172B4D',
            fontSize: 17,
            marginBottom: 16
        },
        subtitle: {
            marginBottom: 16,
            fontFamily: 'SF UI Text Regular',
            fontSize: 14,
        },
        closeIcon: {
            cursor: 'pointer',
            position: 'absolute',
            float: 'right',
            right: 2,
            marginTop: '-22px'
        },
        image: {
            maxHeight: 173,
            '@media(max-width: 460px)': {
                maxHeight: 150
            },
            '@media(max-width: 400px)': {
                maxHeight: 120
            },
            '@media(max-width: 340px)': {
                maxHeight: 100
            },
            '@media(max-width: 290px)': {
                maxHeight: 80
            },
            '@media(max-width: 240px)': {
                maxHeight: 60
            },
            '@media(max-width: 180px)': {
                maxHeight: 40
            }
        }

    }),
)

export {useStyles}
