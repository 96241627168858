import React from 'react'
import ReactDOM from 'react-dom'

// Import the views your app loads
import Connector from './views/Connector'
import {appDetailsState, initSentry, LoadAnalytics, Onboarding, writeLog} from 'trello-shared-resources'

// Globally load the ant design css
import 'antd/dist/antd.css'

// Load the main Orah CSS
import 'trello-shared-resources/dist/assets/fonts/font.css'
import './css/transitions.css'

import ThreadedComments from './components/ThreadedComments'
import EmailNotificationsCheckoutFlow from './components/emailNotifications/onboarding/EmailNotificationsCheckoutFlow'
import ErrorComponent from 'trello-shared-resources/dist/components/onboarding/error/ErrorComponent'
import ThreadedCommentsSettings from './components/settings/ThreadedCommentsSettings'
import MiniOnboarding from './components/emailNotifications/miniOnboarding/MiniOnboarding'
import UnsubscribeDisplay from './components/unsubscribe/UnsubscribeDisplay'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorSection} from 'trello-shared-resources/dist/components/onboarding/error/ErrorSection'

/*
  Parse query string for "apptype" and route to corresponding view
*/
const queryString = require('query-string')
const parsedQueryString = queryString.parse(window.location.search)
const iframePassedAppType = parsedQueryString['apptype']
writeLog('Iframe was passed apptype : ' + iframePassedAppType)

LoadAnalytics()
const beforeSend = (event, hint) => {
    if (hint.originalException.message === 'Network Error') {
        return
    }
    return event
}
initSentry('https://9681e3c98f604187b38444ed01c62fd2@o82263.ingest.sentry.io/6092155', beforeSend)

function getComponentToRender(iframePassedAppType) {
    switch (iframePassedAppType) {
        case 'threadedcomments':
            return <ThreadedComments/>
        case 'emailNotificationsOnboarding':
            return <EmailNotificationsCheckoutFlow/>
        case 'onboarding':
            return <Onboarding context={appDetailsState}/>
        case 'miniOnboarding':
            return <MiniOnboarding/>
        case 'settings':
            return <ThreadedCommentsSettings context={appDetailsState}/>
        case 'manageMyAccount':
            return <ThreadedCommentsSettings context={appDetailsState} selectedMenuItem={'manageMyAccount'}/>
        case 'billingSettings':
            return <ThreadedCommentsSettings context={appDetailsState} selectedMenuItem={'settingsBillingTab'}/>
        case 'error':
            return <ErrorComponent/>
        case 'unsubscribe':
            return <UnsubscribeDisplay memberId={parsedQueryString['memberId']}
                                       emailSubscriptionId={parsedQueryString['emailSubscriptionId']}
                                       boardId={parsedQueryString['boardId'] || ''}/>
        case 'comment':
            if (navigator.cookieEnabled) {
                localStorage.setItem('commentId', parsedQueryString['commentId'])
            }
            window.location.href = parsedQueryString['linkcard']
            break
        default: // We not pass a querystring param for the App Connector
            // This connector is used to instantiate your app's capabilities
            return <Connector/>
    }
}

ReactDOM.render(<ErrorBoundary fallbackRender={({error, resetErrorBoundary}) =>
        <ErrorSection errorMessage={error.message} setErrorMessage={resetErrorBoundary}
                      smallWindow={iframePassedAppType === 'threadedcomments'}/>}> {getComponentToRender(iframePassedAppType)} </ErrorBoundary>
    , document.getElementById('root')
)