import React from 'react'
import { AnnouncementPricingProps } from '../../types/AnnouncementPricingProps'
import { calculateDaysToExpireEvaluation, showPricingBanner } from '../../modules/LicenseService'
import Announcement from './Announcement'
import { Link } from '@material-ui/core'
import imageBannerPrice from '../../assets/images/Robot_TC4.png'
import { supportUrl, TrackActionEvent } from 'trello-shared-resources/dist'
import { useStyles } from './AnnouncementPricingStyles'

export const AnnouncementPricing = (props: AnnouncementPricingProps) => {

    const classes = useStyles()
    const {licenseDetails, notLoggedInOrahUser} = props

    if (!showPricingBanner(licenseDetails)) return <></>

    /**
     * Handler to open the checkout
     */
    const openCheckout = async () => {
        const trelloIframeContext = licenseDetails.trelloIframeContext
        const trelloContextInfo = trelloIframeContext.getContext()
        TrackActionEvent('Settings link', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member,
            action: 'upgrade_link'
        })
        trelloIframeContext.modal({
            url: notLoggedInOrahUser ? './index.html?apptype=manageMyAccount' : './index.html?apptype=billingSettings',
            fullscreen: true,
            resizable: false,
            title: `${process.env.REACT_APP_TRELLO_APP_NAME} Settings`,
            accentColor: '#EBEDF0'
        })
    }

    const daysToExpireEvaluation = calculateDaysToExpireEvaluation(licenseDetails)

    return <Announcement appearance={'warning'}
                         imagePath={imageBannerPrice}
                         title={'Your Threaded Comments license is about to expire 😢'}
                         subtitle={<>In {daysToExpireEvaluation} day{daysToExpireEvaluation > 1 ? 's' : ''} time you
                             won't be able to add any new comments. To continue posting new comments, please renew you
                             license using the link below.</>}
    >
        <>
            <Link href="#" className={classes.link} onClick={openCheckout}>Upgrade Now</Link>
            <Link href={supportUrl} target="_blank" className={classes.link}>Contact support</Link>
        </>
    </Announcement>
}