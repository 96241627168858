import React, {useEffect, useReducer, useState} from 'react'
import {MenuEnum} from '../../types/MenuEnum'
import EmailIcon from '@atlaskit/icon/glyph/email'
import {getMenuContent} from 'trello-shared-resources/dist/services/MenuService'
import NotificationSettings from './notificationSettings/NotificationSettings'
import {Grid} from '@material-ui/core'
import LeftBar from 'trello-shared-resources/dist/components/leftBar/LeftBar'
import {ButtonItem} from '@atlaskit/side-navigation'
import {useStyles} from './ThreadedCommentsSettingsStyles'
import {Skeleton} from 'antd'
import {emailNotificationSettingTitle} from '../emailNotifications/onboarding/email-notifications-messages'
import {ReadonlyWarning} from '../readonlyWarning/ReadonlyWarning'

/**
 * The top level Report 'view'. This wraps the report functionality in the require licensing provider.
 */
const ThreadedCommentsSettingsDisplay = (props: any) => {
    const classes = useStyles()

    const licenseDetails = props.licenseDetails

    const [selectedMenuItem, setSelectedMenuItem] = useState<string>(props.selectedMenuItem ? props.selectedMenuItem : (licenseDetails.isLicensed() ? MenuEnum.NOTIFICATION.toString() : 'manageMyAccount'))
    const [orahUser, setOrahUser] = useState(undefined)
    const [, forceUpdate] = useReducer(x => x + 1, 0)


    useEffect(() => {
        licenseDetails.getUser().then((user: any) => {
            setOrahUser(user)
        })
    }, [licenseDetails])

    if (licenseDetails.isLoading) {
        return <Skeleton active={true}/>
    }

    if (!licenseDetails.trelloIframeContext) {
        return (
            <>
                <p>Whoops! - Unfortunately there was an issue talking to Trello!</p>
                <p>
                    Please refresh and try again, if that does not work then please contact support
                </p>
            </>
        )
    }

    const readonlyWarning = <ReadonlyWarning licenseDetails={licenseDetails} upgradeHandler={() => {
        setSelectedMenuItem('settingsBillingTab')
        forceUpdate()
    }}/>

    const readonlyWarningUnauthorizedUser = <ReadonlyWarning licenseDetails={licenseDetails} upgradeHandler={undefined}/>

    const getMainDialogContent = () => {
        let messageSection
        if(!licenseDetails.isLicensed()) messageSection = orahUser ? readonlyWarning : readonlyWarningUnauthorizedUser
        let mainDialogContent = getMenuContent(licenseDetails, selectedMenuItem.toString(), messageSection, new Date())
        if (!mainDialogContent) {
            if (!licenseDetails.isAuthorized) {
                licenseDetails.trelloIframeContext.modal({
                    url: './index.html?apptype=error',
                    fullscreen: true,
                    resizable: false,
                    title: 'Threaded Comments by Kolekti',
                    accentColor: '#EBEDF0'
                })
                return <Skeleton active={true}/>
            }
            switch (selectedMenuItem) {
                case MenuEnum.NOTIFICATION.toString():
                    mainDialogContent = <NotificationSettings licenseDetails={licenseDetails}/>
                    break
            }
        }
        return mainDialogContent
    }


    return (
        <Grid container className={classes.container}>
            <LeftBar selectedMenuItem={selectedMenuItem.toString()} setSelectedMenuItem={setSelectedMenuItem}
                     externalButtons={licenseDetails.isLicensed() ? [
                         <ButtonItem isSelected={selectedMenuItem === MenuEnum.NOTIFICATION.toString()}
                                     iconBefore={<EmailIcon label="Notification settings"/>}
                                     key={MenuEnum.NOTIFICATION}
                                     onClick={() => setSelectedMenuItem(MenuEnum.NOTIFICATION.toString())}>
                             {emailNotificationSettingTitle}
                         </ButtonItem>
                     ] : []}/>
            {getMainDialogContent()}
        </Grid>
    )

}

export default ThreadedCommentsSettingsDisplay