import {getToken} from 'trello-shared-resources/dist/services/TokenService'
import * as Sentry from '@sentry/browser'
import apigClientFactory from 'aws-api-gateway-client/dist/apigClient'
import CommentData from '../types/CommentData'
import TrelloUrlBuilder from 'trello-shared-resources/dist/modules/url/TrelloUrlBuilder'
import TrelloEntityType from 'trello-shared-resources/dist/modules/url/TrelloEntityType'
import {CommentReaction} from '../types/CommentReaction'
import {IEmojiData} from 'emoji-picker-react'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {CardAttachment} from '../types/CardAttachment'
import {UnreadCommentNotification} from '../types/UnreadCommentNotification'
import Member from '../types/Member'
import {CommentsForCardResult} from '../types/CommentsForCardResult'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import {MemberSubscription} from '../types/MemberSubscription'
import {UnsubscribeFeedbackData} from '../types/UnsubscribeFeedbackData'
import {CommentDataCreateGenerate} from '../types/CommentDataCreateGenerate'
import {CommentDataGenerate} from '../types/CommentDataGenerate'

const config = {
    invokeUrl: process.env.REACT_APP_API_DOMAIN_NAME,
    appId: process.env.REACT_APP_PLUGIN_ID,
    retries: 4,
    retryCondition: (error: any) => error.response && error.response.status === 406,
    retryDelay: 'exponential'
}
export const apigClient = apigClientFactory.newClient(config)

/**
 * Generate authentication headers
 * @param trelloContext to fill all headers
 * @param token the Trello token to call the API
 */
async function generateAuthHeaders(trelloContext: any, token?: string) {
    return {
        headers: {
            'x-member-id': trelloContext.getContext().member,
            'x-user-token': await trelloContext.jwt(),
            'x-trello-app-token': token && token !== '' ? token : await getToken()
        }
    }
}

/**
 * Get all of the comments for the card that is 'active' on the given
 * Trello context.
 *
 * @param licenseDetails The context that we will use to retrieve data.
 */
export async function getCommentsForCard(licenseDetails: any): Promise<CommentsForCardResult> {
    const trelloContext = licenseDetails.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to get worklogs for a card. Try to reload'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/cards/${cardId}/comments`

    const additionalParams = await generateAuthHeaders(trelloContext)
    return apigClient.invokeApi({}, pathTemplate, 'GET', additionalParams, {})
}

/**
 * Generate an object to store the comment
 * @param commentDataCreateGenerate object request to create a comment data
 * @return an object that contains all needed data to create a comment
 */
const generateCommentData = async (commentDataCreateGenerate: CommentDataCreateGenerate) => {
    const jwt = await commentDataCreateGenerate.trelloContext.jwt()
    const context = commentDataCreateGenerate.trelloContext.getContext()
    const parentComment = commentDataCreateGenerate.currentComments.find((comment: any) => (comment.id || comment.idRemovedComment) === ((commentDataCreateGenerate.parentCommentId !== '' && commentDataCreateGenerate.parentCommentId != null) ? commentDataCreateGenerate.parentCommentId : commentDataCreateGenerate.commentId))

    return {
        token: jwt,
        memberId: context.member,
        creationDate: commentDataCreateGenerate.creationDate.valueOf(),
        boardId: context.board,
        cardId: context.card,
        comment: commentDataCreateGenerate.commentContent,
        parentComment: parentComment,
        parentCommentId: commentDataCreateGenerate.parentCommentId !== '' ? commentDataCreateGenerate.parentCommentId : null,
        mentionedMembers: commentDataCreateGenerate.mentionedMembers,
        boardAndOrganizationMembers: commentDataCreateGenerate.boardAndOrganizationMembers,
        commentId: commentDataCreateGenerate.commentId
    }
}

/**
 * Generate an object to store the comment
 * @param commentDataUpdateGenerate The context that we will use to retrieve data.
 * @return an object that contains all needed data to update a comment
 */
const generateUpdateCommentData = async (commentDataUpdateGenerate: CommentDataGenerate) => {
    const jwt = await commentDataUpdateGenerate.trelloContext.jwt()
    const context = commentDataUpdateGenerate.trelloContext.getContext()
    const parentComment = commentDataUpdateGenerate.currentComments.find((comment: any) => (comment.id || comment.idRemovedComment) === ((commentDataUpdateGenerate.parentCommentId !== '' && commentDataUpdateGenerate.parentCommentId !== null) ? commentDataUpdateGenerate.parentCommentId : commentDataUpdateGenerate.commentId))

    return {
        token: jwt,
        boardId: context.board,
        cardId: context.card,
        comment: commentDataUpdateGenerate.commentContent,
        parentCommentId: commentDataUpdateGenerate.parentCommentId !== '' ? commentDataUpdateGenerate.parentCommentId : null,
        mentionedMembers: commentDataUpdateGenerate.mentionedMembers,
        parentComment: parentComment,
        boardAndOrganizationMembers: commentDataUpdateGenerate.boardAndOrganizationMembers
    }
}

/**
 * Store a comment with the given info
 * @param commentDataGenerate object to store a comment
 */
export async function storeComment(commentDataGenerate: CommentDataCreateGenerate) {
    if (!commentDataGenerate.trelloContext || !commentDataGenerate.trelloContext.getContext()) {
        const error: string = 'There were an error trying to store a comment.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }

    const dataToSend = await generateCommentData(commentDataGenerate)
    const cardId = commentDataGenerate.trelloContext.getContext().card
    const pathTemplate = `/cards/${cardId}/comments`
    const additionalParams = await generateAuthHeaders(commentDataGenerate.trelloContext)
    return apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, dataToSend)
        .catch(catchExceptionAndSendToSentry)
}

export function isReactionRemoved(comment: CommentData, emojiData: IEmojiData, memberId: string) {
    return comment.reactions && comment.reactions.find(reaction => reaction.emoji === emojiData.emoji && reaction.memberId === memberId)
}

/**
 * Add or remove a reaction for a comment
 * @param trelloContext The context that we will use to retrieve data.
 * @param emojiData emoji info, such as the icon string and the emoji name
 * @param comment comment info
 * @param memberId
 */
export async function addOrRemoveReaction(trelloContext: any, emojiData: IEmojiData, comment: CommentData, memberId: string) {
    if (comment.id) {
        const trelloContextInfo = trelloContext.getContext()
        if (!trelloContext || !trelloContext.getContext()) {
            const error: string = 'There were an error trying to store a comment.'
            Sentry.captureException(error)
            return Promise.reject({message: error})
        }

        const dataToSend: CommentReaction = generateReactionData(trelloContext, emojiData)
        const cardId = trelloContext.getContext().card
        const pathTemplate = `/cards/${cardId}/comments/${comment.id}/reaction`
        const additionalParams = await generateAuthHeaders(trelloContext)
        if (isReactionRemoved(comment, emojiData, memberId)) {
            await deleteReaction(pathTemplate, additionalParams, dataToSend)
            TrackActionEvent('Reaction', trelloContextInfo, {
                board_id: trelloContextInfo.board,
                card_id: trelloContextInfo.card,
                action: 'remove'
            })
        } else {
            await storeReaction(pathTemplate, additionalParams, dataToSend)
            TrackActionEvent('Reaction', trelloContextInfo, {
                board_id: trelloContextInfo.board,
                card_id: trelloContextInfo.card,
                action: 'add'
            })
        }
    }
}

/**
 * Store an user emoji reaction for a comment
 * @param pathTemplate endpoint to call
 * @param additionalParams auth headers
 * @param dataToSend emoji info, such as the icon string and the emoji name
 */
async function storeReaction(pathTemplate: string, additionalParams: any, dataToSend: any) {
    return apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, dataToSend)
        .catch(catchExceptionAndSendToSentry)
}

/**
 * Delete a emoji reaction for a comment
 * @param pathTemplate endpoint to call
 * @param additionalParams auth headers
 * @param dataToSend emoji info, such as the icon string and the emoji name
 */
async function deleteReaction(pathTemplate: string, additionalParams: any, dataToSend: any) {
    return apigClient.invokeApi({}, pathTemplate, 'DELETE', additionalParams, dataToSend)
        .catch(catchExceptionAndSendToSentry)
}

/**
 * Generate reaction data needed to be stored or deleted
 * @param trelloContext The context that we will use to retrieve data.
 * @param emojiData emoji info, such as the icon string and the emoji name
 */
export const generateReactionData = (trelloContext: any, emojiData: IEmojiData): CommentReaction => {
    return {
        emoji: emojiData.emoji,
        emojiName: emojiData.names && emojiData.names.length > 0 ? emojiData.names[0] : '',
        memberId: trelloContext.getContext().member,
        creationDate: new Date().valueOf().toString()
    }
}

/**
 * Update a comment with the given info
 * @param commentDataUpdateGenerate object to update a comment
 */
export async function updateComment(commentDataUpdateGenerate: CommentDataGenerate) {
    if (!commentDataUpdateGenerate.trelloContext || !commentDataUpdateGenerate.trelloContext.getContext()) {
        const error: string = 'There were an error trying to store a comment.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const dataToSend = await generateUpdateCommentData(commentDataUpdateGenerate)
    const cardId = commentDataUpdateGenerate.trelloContext.getContext().card
    const pathTemplate = `/cards/${cardId}/comments/${commentDataUpdateGenerate.commentId}`
    const additionalParams = await generateAuthHeaders(commentDataUpdateGenerate.trelloContext)

    return apigClient.invokeApi({}, pathTemplate, 'PUT', additionalParams, dataToSend)
        .catch(catchExceptionAndSendToSentry)
}

/**
 * Finds the given comment and removes it.
 * @param trelloContext The context provided by trello that we use to read and write data.
 * @param commentId The ID of the comment that we want to remove.
 */
export async function removeComment(trelloContext: any, commentId: string) {
    if (!trelloContext || !trelloContext.getContext() || !commentId) {
        const error: string = 'There were an error trying to remove a comment.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/cards/${cardId}/comments/${commentId}`
    const additionalParams = await generateAuthHeaders(trelloContext)
    return apigClient.invokeApi({}, pathTemplate, 'DELETE', additionalParams, {})
}

/**
 * Find member by the given Id
 * @param licenseDetails The context that we will use to retrieve data.
 * @param memberId
 * @return a Promise<Member>
 */
export async function getMemberById(licenseDetails: any, memberId: string): Promise<Member> {
    if (!licenseDetails || !memberId) {
        const error: string = 'There were an error trying to find a member.'
        Sentry.captureException(error)
        return Promise.reject(error)
    }
    const key = licenseDetails.apiKey
    const token = await getToken()

    const getMemberURL = new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Members)
        .withEntityId(memberId)
        .build()

    return get(getMemberURL)
}

/**
 * Retrieve board's members from Trello API
 */
export async function getMembershipsForBoard(licenseDetails: any, boardId: string): Promise<Array<Member>> {
    const key = licenseDetails.apiKey
    const token = await getToken()
    return get(getBoardMembershipsUrl(key, token, boardId)).then(members => members.map((member: any) => member.member))
}

/**
 * Make a request to the backend to get the notifications for the given board for the current user
 * @param trelloContext The context that we will use to retrieve data.
 * @return UnreadCommentNoficiation[] a list of UnreadCommentNoficiation
 */
async function getUnreadCommentNotificationMember(trelloContext: any): Promise<UnreadCommentNotification[]> {
    const token = await getToken(trelloContext)
    if (token && trelloContext.getContext()) {
        const pathTemplate = `/unreadComments`
        const additionalParams = await generateAuthHeaders(trelloContext, token)
        const unreadCommentsNotifications = await apigClient.invokeApi({}, pathTemplate, 'GET', additionalParams, {})
        return unreadCommentsNotifications.status === 200 ? unreadCommentsNotifications.data : []
    }
    return []
}

/**
 * Get the unread notifications and store, on each card, the number of unread comments or 0
 * @param trelloContext The context that we will use to retrieve data.
 */
export async function storeUnreadCommentNotification(trelloContext: any) {
    try {
        const unreadCommentNotifications = await getUnreadCommentNotificationMember(trelloContext)
        const cardsForCurrentBoard: Array<string> = await trelloContext.cards('id')?.map((card: { id: string }) => card.id)

        if(unreadCommentNotifications) {
            const unreadCommentNotificationCountByCards = unreadCommentNotifications
                .filter((unreadCommentNotification: UnreadCommentNotification) => cardsForCurrentBoard?.includes(unreadCommentNotification.cardId))
                .reduce((entryMap, unreadCommentNotification) =>
                        entryMap.set(unreadCommentNotification.cardId, (entryMap.get(unreadCommentNotification.cardId) + 1) || 1),
                    new Map())

            cardsForCurrentBoard?.forEach((cardId: string) => {
                const unreadCommentNotificationCountByCard = unreadCommentNotificationCountByCards.get(cardId)
                trelloContext.set(cardId, 'private', 'threaded_comments_notifications', unreadCommentNotificationCountByCard || 0)
            })
        }
    } catch (error) {
        if (error.message && !error.message.includes('Missing context') && !error.message.includes('Plugin disabled')
            && !error.message.includes('Invalid context, missing board') &&
            (!error.response || (error.response && error.response.status !== 406)))
            throw error
    }
}

/**
 * Exception handler. Reports the error to Sentry and logs the error into the user console
 * Translate the Trello error into a customer-friendly output.
 *
 * @param error
 */
const catchExceptionAndSendToSentry = (error: any) => {
    Sentry.captureException(error)
    throw new Error(error.message)
}

/**
 * Get user email related to the current member id.
 * The data received is encrypted, so a decryption is needed to get the email address in plain text
 * @param trelloContext The context that we will use to retrieve data.
 * @param emailSubscriptionId row ID provided on the Unsubscribe link present on every email notification
 */
export async function getUserEmailForNotifications(trelloContext: any, emailSubscriptionId: string = '') {
    const pathTemplate = '/emails/getUserEmail'
    const additionalParams = await generateAuthHeaders(trelloContext, trelloContext.token || '') as any
    additionalParams.headers['x-email-subscription-id'] = emailSubscriptionId
    additionalParams.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
    additionalParams.headers['Pragma'] = 'no-cache'
    additionalParams.headers['Expires'] = '0'
    const response = await apigClient.invokeApi({}, pathTemplate, 'GET', additionalParams, {})
        .catch(catchExceptionAndSendToSentry)

    if (response.status !== 200) {
        const errorMessage = 'There was an error trying to get user email for notifications. Try to reload'
        Sentry.captureException(errorMessage)
        throw new Error(errorMessage)
    }

    const emailData = response.data
    const encryptedEmail = emailData.email
    if (encryptedEmail) {
        try {
            const encryptionKey = emailData.memberId + config.appId
            const bytes = AES.decrypt(encryptedEmail, encryptionKey)
            const originalEmail = bytes.toString(Utf8)
            emailData.email = originalEmail
        } catch (error) {
            Sentry.captureException(error)
            throw new Error(error)
        }
    }
    return emailData
}

/**
 * Store user email to allow to get notifications.
 * The email gets encryption and send it to the db so it won't be stored as plain text.
 * @param trelloContext The context that we will use to retrieve data.
 * @param userEmail The user email
 * @param update boolean when true, it will send an update operation. If false, will send a post
 * @return the result from the request
 */
export async function storeUserEmailForNotifications(trelloContext: any, userEmail: string, update: boolean = false) {
    try {
        const memberId = trelloContext.getContext().member
        const encryptionKey = memberId + config.appId
        const ciphertext = AES.encrypt(userEmail, encryptionKey).toString()

        const dataToSend = {
            email: ciphertext
        }
        const pathTemplate = '/emails/storeUserEmail'
        const additionalParams = await generateAuthHeaders(trelloContext)
        return apigClient.invokeApi({}, pathTemplate, update ? 'PUT' : 'POST', additionalParams, dataToSend)
    } catch (errorMessage) {
        Sentry.captureException(errorMessage)
        throw new Error(errorMessage)
    }
}

/**
 * Delete user email for notifications
 * @param trelloContext The context that we will use to retrieve data.
 * @param emailSubscriptionId row ID provided on the Unsubscribe link present on every email notification
 * @return the result from the request
 */
export async function deleteUserEmailForNotifications(trelloContext: any, emailSubscriptionId: string = '') {
    try {
        const pathTemplate = '/emails/deleteUserEmail'
        const additionalParams = await generateAuthHeaders(trelloContext, trelloContext.token || '') as any
        additionalParams.headers['x-email-subscription-id'] = emailSubscriptionId
        await apigClient.invokeApi({}, pathTemplate, 'DELETE', additionalParams)
    } catch (errorMessage) {
        Sentry.captureException(errorMessage)
        throw new Error(errorMessage)
    }
}

/**
 * Mark all comment notifications as readed on the current card for the current member if exist any
 * @param trelloContext The context that we will use to retrieve data.
 */
export async function markCommentAsRead(trelloContext: any): Promise<void> {
    const cardId = trelloContext.getContext().card
    trelloContext.set(cardId, 'private', 'threaded_comments_notifications', 0)
    const pathTemplate = `/cards/${cardId}/unreadComments`
    const additionalParams = await generateAuthHeaders(trelloContext)
    await apigClient.invokeApi({}, pathTemplate, 'DELETE', additionalParams, {})
}

/**
 * Check if the given member belongs to the boardOrganization
 * @param member member to check if belongs to the board organization
 * @param board current board
 * @return{Promise<boolean>} true if the given member belongs to the board organization
 */
async function isMemberBoardOrbanization(member: Member | undefined, board: any): Promise<boolean> {
    return member !== undefined && member !== null && member.idOrganizations != null && board !== undefined && board !== null
        && member.idOrganizations.includes(board.idOrganization)
}

/**
 * Get organization members for the current board
 * @param licenseDetails The context that we will use to retrieve data.
 * @param searchAndStoreMember method to search a member on the current state or find it through the Trello API
 * @return an array with members or empty array if we can't get board data or if the board doesn't have an org
 */
export async function getOrganizationMembersOnCurrentBoard(licenseDetails: any, searchAndStoreMember: (membersId: string) => Promise<Member | undefined>): Promise<Member[]> {
    const board = await getCurrentBoard(licenseDetails)
    const currentMember = await searchAndStoreMember(licenseDetails.trelloIframeContext.getContext().member)
    const isCurrentMemberBoardOrganization = await isMemberBoardOrbanization(currentMember, board)
    if (board && board.id && board.idOrganization && (isCurrentMemberBoardOrganization || (!currentMember && !isCurrentMemberBoardOrganization))) {
        const key = licenseDetails.apiKey
        const token = await getToken()
        const organizationMembers = await get(getOrganizationMembershipsUrl(key, token, board.idOrganization))
        return organizationMembers.length ? organizationMembers.map((member: any) => member.member) : []
    }
    return []
}

/**
 * Get the current board data
 * @param licenseDetails The context that we will use to retrieve data.
 * @return an object with the board data
 */
export async function getCurrentBoard(licenseDetails: any) {
    const key = licenseDetails.apiKey
    const token = await getToken()
    const boardId = licenseDetails.trelloIframeContext.getContext().board
    return await get(getBoardUrl(key, token, boardId))
}

/**
 * Generate the Trello API URL to get board data
 * @param key
 * @param token
 * @param boardId
 */
function getBoardUrl(key: string, token: string, boardId: string) {
    return new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Boards)
        .withEntityId(boardId)

        .build()
}

/**
 * Generate the Trello API URL to get board's members
 * @param key
 * @param token
 * @param boardId
 */
function getBoardMembershipsUrl(key: string, token: string, boardId: string) {
    return new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Boards)
        .withEntityId(boardId)
        .withEntityAction('memberships')

        // Search parameters
        .addQueryParameter('member', 'true')
        .addQueryParameter('member_fields', 'id,avatarHash,avatarUrl,initials,fullName,username,confirmed,memberType')

        .build()
}

/**
 * Generate the Trello API URL to get Organization's members
 * @param key
 * @param token
 * @param organizationId
 */
function getOrganizationMembershipsUrl(key: string, token: string, organizationId: string) {
    return new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Organizations)
        .withEntityId(organizationId)
        .withEntityAction('memberships')

        // Search parameters
        .addQueryParameter('member', 'true')
        .build()
}

/**
 * Perform a GET request
 * @param url the address to perform the request
 * @return the JSON that contains the response or throw an error in case of error
 */
async function get(url: string) {
    const response = await fetch(url, {method: 'GET'})
    if (response.ok) {
        return await response.json()
    } else {
        const message = await response.text()
        throw new Error(message)
    }
}

/**
 * Get the attachments for the current card
 * @param licenseDetails data needed to store on the trello API
 * @return {Promise<Array>} An array of object that contains attachments data or an empty array otherwise
 */
export async function getCardAttachments(licenseDetails: any): Promise<CardAttachment[]> {
    const trelloContext = licenseDetails.trelloIframeContext
    const card = await trelloContext?.card('all')
    return card?.attachments ?? []
}

/**
 * Upload the given file to the current card
 * @param licenseDetails data needed to attach the file using the trello API
 * @param file the file provided by the user to be uploaded to the card
 * @return Promise<CardAttachment|null> a promise that will return the cardAttachment data or null if something goes wrong
 */
export async function uploadAttachmentToCard(licenseDetails: any, file: File): Promise<CardAttachment | null> {
    const cardId = licenseDetails.trelloIframeContext.getContext().card
    const formData = new FormData()
    formData.append('file', file)
    formData.append('token', await getToken())
    formData.append('key', licenseDetails.apiKey)

    const response = await fetch(`https://api.trello.com/1/cards/${cardId}/attachments`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: formData
    })
    return (response.ok && response.status === 200 ? response.json() : null)
}

/**
 * Collect an array of unique members
 * @param accumulator the array to accumulate unique members
 * @param currentMember current member to check if it is unique or not
 */
export const uniqueMemberCalculator = (accumulator: Member[], currentMember: Member) => {
    if (!accumulator.some((member: Member) => member.id === currentMember.id)) {
        accumulator.push(currentMember)
    }
    return accumulator
}

/**
 * Get if every member that can be mentions is subscribed to receive notifications or not
 * @param trelloContext The context that we will use to retrieve data.
 * @param members an array with members that can be mentions on the comment
 */
export async function getMembersSubscriptionForNotifications(trelloContext: any, members: Member[]): Promise<void> {
    const token = await getToken(trelloContext)
    if (token && trelloContext.getContext() && members.length) {
        const pathTemplate = '/emails/getUsersSubscription'
        const additionalParams = await generateAuthHeaders(trelloContext, token)
        const body = {memberIds: members.map(member => member.id)}
        const usersSubscriptionForNotifications = await apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, body)
        if (usersSubscriptionForNotifications.status === 200) {
            usersSubscriptionForNotifications.data.forEach((userSubscription: MemberSubscription) => {
                const member = members.find(member => member.id === userSubscription.memberId)
                if (member) {
                    member.isSubscribed = userSubscription.isSubscribed
                }
            })
        }
    }
}

/**
 * Store the given feedback information when a user unsubscribe
 * @param trelloContext The context that we will use to retrieve data.
 * @param unsubscribeFeedbackData the form filled by the user
 * @param emailSubscriptionId row ID provided on the Unsubscribe link present on every email notification
 */
export async function storeUnsubscribeFeedback(trelloContext: any, unsubscribeFeedbackData: UnsubscribeFeedbackData, emailSubscriptionId: string) {
    try {
        const pathTemplate = '/emails/unsubscriptionFeedback'
        const additionalParams = await generateAuthHeaders(trelloContext, trelloContext?.token || '') as any
        additionalParams.headers['x-email-subscription-id'] = emailSubscriptionId
        return apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, unsubscribeFeedbackData)
    } catch (errorMessage) {
        Sentry.captureException(errorMessage)
        throw new Error(errorMessage)
    }
}